<template>
	<div class="page-layout" v-loading="loading">
		<gc-header headerData="batchDirectiveManage">
			<template #ops>
				<!-- 指令下发-->
				<send-command
					@update-list="updateList"
					:options="options"
					v-if="$has('monitor:run:batch-command:insert')"
				></send-command>
			</template>
		</gc-header>
		<div class="run-main directive-content">
			<div class="left" v-if="tenantType == 1">
				<GcRegionTree v-model="form.waterRegionCode" :initSelect="false" />
			</div>
			<div class="right">
				<div class="filter">
					<gc-custom-search
						class="search"
						keyWord="设备类型"
						width="260px"
						type="select"
						filterable
						:search.sync="form.deviceTypeId"
						:searchOption="options.deviceTypeId"
						:needAllForSearch="true"
					></gc-custom-search>
					<gc-custom-search
						class="search"
						keyWord="指令名称"
						width="260px"
						:search.sync="form.taskName"
					></gc-custom-search>
					<gc-custom-search
						class="search"
						v-if="tenantType == 1"
						key-word="客户类型"
						type="select"
						width="260px"
						:search.sync="form.userType"
						:searchOption="options.userType"
						:search-option-key="{ label: 'name', value: 'defaultValue' }"
						needAllForSearch
					></gc-custom-search>
					<gc-custom-search
						class="search"
						v-if="tenantType == 1"
						width="470px"
						key-word="指令日期"
						type="date"
						date-picker-type="datetimerange"
						:search.sync="form.time"
					>
						<template v-slot:icon>
							<i class="iconfont icon-riqi"></i>
						</template>
					</gc-custom-search>
					<div class="serach-ops">
						<el-button type="primary" @click="getBatchCommandRecordsList('')" v-click-blur>查 询</el-button>
						<el-button @click="reset" v-click-blur>重 置</el-button>
					</div>
					<gc-column-setting
						:storageKey="`localcolumn-batch-directive-${tenantType}`"
						:columns="columns"
						@update-column="data => (columns = data)"
					></gc-column-setting>
				</div>
				<div class="table">
					<gc-table
						:columns="columnList"
						:table-data="tableData"
						:border="true"
						:showPage="true"
						pagination-type="special"
						:page-params="pageParams"
						@current-page-change="currentPageChange"
					></gc-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sendCommand from './send-command/index.vue'
import { apiBatchCommandRecordsList } from '@/apis/batchDirectiveManage.api.js'
import deviceTypeOption from '../mixins/deviceTypeOption.js'
import { handleParams } from '@/utils'

export default {
	name: 'batchDirectiveManage',
	components: { sendCommand },
	mixins: [deviceTypeOption],
	data() {
		return {
			form: {
				deviceTypeId: null, //设备类型
				taskName: null, //指令名称
				waterRegionCode: null, //区域编码
				userType: null, //客户类型
				time: null,
			}, //筛选条件
			tableData: [],
			pageParams: {
				hasNext: false,
				idOffset: '', //下一页offset
				currentIdOffset: '', //当前请求offset
			},
			loading: false,
			columns: [],
		}
	},
	computed: {
		tenantType() {
			// 0-燃气租户 1-水务租户
			return this.$store.getters.userInfo.tenantType
		},

		columnList() {
			return this.columns.filter(o => o.selected)
		},
		userInfo() {
			return this.$store.getters.userInfo
		},
	},

	watch: {
		'form.waterRegionCode': {
			handler(val) {
				if (val) {
					this.getBatchCommandRecordsList('')
				}
			},
		},

		tenantType: {
			handler(newVal) {
				if (newVal === '1') {
					this.columns = [
						{
							key: 'deviceTypeName',
							name: '设备类型',
							dragDisable: true,
							disabledSelect: true,
						},
						{
							key: 'userTypeName',
							name: '客户类型',
						},
						{
							key: 'waterRegionName',
							name: '所属区域',
						},
						{
							key: 'orgName',
							name: '所属机构',
						},
						{
							key: 'cmdModelName',
							name: '指令名称',
						},
						{
							key: 'totalCount',
							name: '设备总数',
						},
						{
							key: 'successCount',
							name: '成功数',
						},
						{
							key: 'waitCount',
							name: '待执行数',
							render: (h, row) => {
								return h(
									'span',
									{
										style: {
											color: '#19BE6B',
										},
									},
									row.waitCount,
								)
							},
						},
						{
							key: 'failCount',
							name: '失败数',
						},
						{
							key: 'createTime',
							name: '生成时间',
							minWidth: 180,
						},
						{
							key: 'oprate',
							name: '操作',
							width: 180,
							render: (h, row) => {
								//  自定义表格内容展示
								return this.$has('monitor:run:batch-command:select')
									? h(
											'el-button',
											{
												props: {
													type: 'text',
													size: 'medium',
												},
												on: {
													click: () => {
														this.$router.push({
															path: '/run/direct-detail',
															query: {
																taskNo: row.taskNo,
																commandTaskId: row.commandTaskId,
															},
														})
													},
												},
											},
											'查看详情',
									  )
									: h('span', {}, '/')
							},
							fixed: 'right',
						},
					]
				} else if (newVal === '0') {
					this.columns = [
						{
							key: 'deviceTypeName',
							name: '设备类型',
							dragDisable: true,
							disabledSelect: true,
						},
						{
							key: 'orgName',
							name: '所属机构',
						},
						{
							key: 'cmdModelName',
							name: '指令名称',
						},
						{
							key: 'totalCount',
							name: '设备总数',
						},
						{
							key: 'successCount',
							name: '成功数',
						},
						{
							key: 'waitCount',
							name: '待执行数',
							render: (h, row) => {
								return h(
									'span',
									{
										style: {
											color: '#19BE6B',
										},
									},
									row.waitCount,
								)
							},
						},
						{
							key: 'failCount',
							name: '失败数',
						},
						{
							key: 'createTime',
							name: '生成时间',
							minWidth: 180,
						},
						{
							key: 'oprate',
							name: '操作',
							width: 180,
							render: (h, row) => {
								//  自定义表格内容展示
								return this.$has('monitor:run:batch-command:select')
									? h(
											'el-button',
											{
												props: {
													type: 'text',
													size: 'medium',
												},
												on: {
													click: () => {
														this.$router.push({
															path: '/run/direct-detail',
															query: {
																taskNo: row.taskNo,
																commandTaskId: row.commandTaskId,
															},
														})
													},
												},
											},
											'查看详情',
									  )
									: h('span', {}, '/')
							},
							fixed: 'right',
						},
					]
				}
			},
			immediate: true,
		},
	},
	mounted() {
		this.getBatchCommandRecordsList('')
		this.$set(this.options, 'userType', this.$store.getters?.dataList?.userType || [])
		this.tenantType == 1 && this.$set(this.form, 'waterRegionCode', this.userInfo.defaultWaterRegionCode)
	},
	methods: {
		// 获取批量指令记录列表
		getBatchCommandRecordsList(currentIdOffset) {
			this.loading = true
			this.pageParams.currentIdOffset = currentIdOffset
			let params = {
				limit: 10,
				idOffset: this.pageParams.currentIdOffset,
			}
			if (this.form.time && this.form.time.length > 0) {
				params['startTime'] = this.form.time[0].getTime()
				params['endTime'] = this.form.time[1].getTime()
			}
			apiBatchCommandRecordsList({
				...params,
				...handleParams(this.form),
			})
				.then(res => {
					this.pageParams.hasNext = res.hasNext
					this.pageParams.idOffset = res.idOffset
					this.tableData = res.records || []
				})
				.finally(() => {
					this.loading = false
				})
		},
		// 重置
		reset() {
			this.form = this.$options.data().form
			this.getBatchCommandRecordsList('')
		},
		// 翻页
		currentPageChange(currentIdOffset) {
			this.getBatchCommandRecordsList(currentIdOffset)
		},
		// 指令下发后更新列表
		updateList() {
			this.getBatchCommandRecordsList('')
		},
	},
}
</script>
<style lang="scss" scoped>
@import '../runLayout.scss';
.directive-content {
	flex-direction: row;
	padding: 0 12px;
	.left {
		width: 300px;
		height: 100%;
		padding: 20px 8px 8px 0;
		border-right: 1px solid #f1f1f1;
		& + .right {
			padding-left: 20px;
		}
	}
	.right {
		flex: 1;
		width: 0;
		padding-left: 20px;
		display: flex;
		height: 100%;
		flex-direction: column;
		.filter {
			display: flex;
			flex-wrap: wrap;
			padding-top: 20px;
			.search {
				margin-right: 20px;
				margin-bottom: 14px;
			}
		}
		.table {
			flex: 1;
			height: 0;
		}
	}
}
</style>
